import { env } from "../config"

export function getWebsiteHost() {
  return (
    env("WEBSITE_HOST") ||
    process.env.NEXT_PUBLIC_WEBSITE_HOST ||
    "https://citizenshipper.com"
  )
}

export function getAppHost() {
  return (
    env("APP_HOST") ||
    process.env.NEXT_PUBLIC_APP_HOST ||
    "https://app.citizenshipper.com"
  )
}

export function getTrustPilotReviewCount() {
  const trustpilotReviewCount = env("TRUSTPILOT_REVIEW_COUNT")
    ? Number(env("TRUSTPILOT_REVIEW_COUNT")).toLocaleString("en-US")
    : "4,356"
  const trustpilotReviewTotal = env("TRUSTPILOT_REVIEW_COUNT")
    ? Number(env("TRUSTPILOT_REVIEW_COUNT"))
    : 4356

  return {
    trustpilotReviewCount,
    trustpilotReviewTotal,
  }
}
